import React from 'react';
import M from 'materialize-css';
import { database } from './firebase'
// import { firestoreObj } from './firebase'
import { firestoreObj } from './firebase'
import source from './warning.mp3'
import './App.css';


// #01ce84 Green
// #feb800 Yellow
// #ff494e Red

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paths: [],
            heading: 'LOADING...',
            backgroundColor: 'dimgrey',
            s1_Speed: 0,
            station1_Color: '#2196f3',
            s2_Speed: 0,
            station2_Color: '#2196f3',
            s3_Speed: 0,
            station3_Color: '#2196f3',
            Current_Speed: 0,
            Expected_speed: 0,
            somme1: 0,
            somme2: 0,
            somme3: 0,
            isplaying: false,
            index: 0,

        }
    }
    componentWillMount() {
        console.log("Will Mount is now running! ")

        // firestoreObj.collection('files').get().then(snapshot => {
        //   this.setState({
        //     paths: snapshot.docs.map(doc => doc.data()['downloadUrl'])
        //   });
        //   console.log('Download paths: ',this.state.paths);
        // });

        firestoreObj.collection('files').onSnapshot(snapshot => {
            this.setState({
                paths: snapshot.docs.map(doc => doc.data()['downloadUrl'])
            });
            console.log("Current data: ", this.state.paths);
        })

        database.ref('/Places/restaurants/2').on("value", function(dataSnapshot) {
            let Data = dataSnapshot.val();
            console.log("Total data to be accessed:", Data);
            if (!Data) return;
            //let s1_Speed = Data.Station1_speed;
            //let s2_Speed = Data.Station2_speed;
            //let s3_Speed = Data.Station3_speed;

            let Expected_speed = Data.Queue / Data.Max_time_in_queue;
            let Max_time_in_queue = Data.Max_time_in_queue;
            let station1 = Data.station1
            let station2 = Data.station2
            let station3 = Data.station3
            let somme1 = station1 + station2 //8
            let somme2 = station1 + station3 //10
            let somme3 = station2 + station3 //12  

            //let station1_Color = s1_Speed >= Expected_speed / 3 ? "#01ce84" : (Expected_speed - 3) /3 <= s1_Speed && s1_Speed < Expected_speed / 3 ? '#feb800' : "#ff494e";
            //let station2_Color = s2_Speed >= Expected_speed / 3 ? "#01ce84" : (Expected_speed - 3) /3 <= s2_Speed && s2_Speed < Expected_speed / 3 ? '#feb800' : "#ff494e";
            //let station3_Color = s3_Speed >= Expected_speed / 3 ? "#01ce84" : (Expected_speed - 3) /3 <= s3_Speed && s3_Speed < Expected_speed / 3 ? '#feb800' : "#ff494e";
            // console.log("Seperated colors: ",station1_Color, station2_Color, station3_Color);
            let Current_Speed = Data.Current_speed;
            let backgroundColor = Current_Speed >= Expected_speed ? "#01ce84" : Expected_speed * 0.7 <= Current_Speed && Current_Speed < Expected_speed ? '#feb800' : "#ff494e";
            let heading = backgroundColor === '#01ce84' ? "NORMAL" : backgroundColor === '#feb800' ? "ATTENTION" : "CRITIQUE";
            let audioFun;
            console.log(heading);
            console.log("somme123", this.state.somme1, this.state.somme2, this.state.somme3);
            console.log("isplaying", this.state.isplaying)
            console.log("index1", this.state.index)
            if (heading === 'CRITIQUE' || this.state.somme1 > 7 || this.state.somme2 > 9 || this.state.somme3 > 11) {
                let audioElem = document.getElementById('playAudio');
                if (!this.state.isplaying) {
                    this.setRandomMusic(audioElem);

                    this.setState({ isplaying: true });
                    console.log("Now The Audio Should be played");
                }

                audioFun = setInterval(() => {
                    //debugger;
                    /* If path array length is greater than 0
                      - Call randomizeIndex()
                      - Select random index from path array to get random URL
                    */
                    console.log("audioElem.paused", audioElem.paused);
                    if (audioElem.duration > 0 && audioElem.paused) {
                        if (this.state.index < this.state.paths.length - 1) {
                            let ind = this.state.index + 1
                            this.setState({ index: ind });
                            console.log("index", ind);
                        } else {
                            this.setState({ index: 0 });
                        }
                        this.setRandomMusic(audioElem);

                        // audioElem.play();
                        console.log("AudiFun", audioFun, " - heading", this.state.heading);

                        if (this.state.heading !== 'CRITIQUE' && this.state.somme1 <= 7 && this.state.somme2 <= 9 && this.state.somme3 <= 11) {
                            console.log("Waiting");
                            //setTimeout(() => {  console.log("World!"); }, 60*1000);
                            console.log("Waited 5s");
                            audioElem.src = '';
                            clearInterval(audioFun);
                            this.setState({ isplaying: false });
                            console.log("clearing Interval", audioFun);
                        }
                    }
                }, 3000);
            }

            this.setState({ Current_Speed, heading, somme1, somme2, somme3, backgroundColor, Expected_speed, Max_time_in_queue });
        }.bind(this));
    }

    componentDidMount() {
        M.AutoInit();
    }

    setRandomMusic(audioElem) {
        if (this.state.paths.length) {
            //let randomIndex = this.randomizeIndex(this.state.paths.length);
            let randomUrl = this.state.paths && this.state.paths[this.state.index];
            console.log('Now playing:', randomUrl);
            audioElem.src = randomUrl;
        } else {
            audioElem.src = source;
        }
    }

    randomizeIndex = (n) => {
        return Math.floor(Math.random() * n);
    }

    render() {
        return ( <
            div className = "App"
            style = {
                { background: this.state.backgroundColor, height: "100%", paddingBottom: window.innerHeight }
            } >

            <
            div className = 'container-fluid' >
            <
            div className = 'row'
            style = {
                { margin: 0, paddingTop: 30 }
            } >

            <
            div className = ""
            style = {
                { marginTop: 0, background: 'transparent' }
            } >

            { /* HEADING */ } <
            h2 style = {
                { fontWeight: 'bolder', color: 'white', padding: 20, marginTop: 0 }
            } > { this.state.heading } < /h2>


            { /* TOP TOTAL DETAILS */ } <
            div className = "card-content"
            style = {
                { paddingBottom: 0 }
            } >
            <
            div className = "row"
            style = {
                { margin: 0 }
            } >
            <
            div className = "col offset-l2 l4 offset-m1 m5 s12"
            id = 'currentCard' >
            <
            div className = "card-panel"
            style = {
                { color: this.state.backgroundColor, background: 'white', borderRadius: 5 }
            } >
            <
            span > < div className = 'flow-text'
            style = {
                { fontSize: 25, fontWeight: 'bold' }
            } > Vitesse actuelle < /div> <
            span style = {
                { fontSize: 100, fontWeight: 'bold' }
            } > { this.state.Current_Speed.toFixed(1) } < /span> <i className='material-icons'>directions_walk</i > /min < /
            span >


            <
            audio src = ''
            title = "audioPlayer"
            id = "playAudio"
            autoPlay allow = "autoplay"
            hidden > < /audio>

            <
            /div> < /
            div > <
            div className = "col l4 m5 s12" >
            <
            div className = "card-panel"
            style = {
                { color: this.state.backgroundColor, background: 'white', borderRadius: 5 }
            } >
            <
            span > < div className = 'flow-text'
            style = {
                { fontSize: 25, fontWeight: 'bold' }
            } > Vitesse attendue < /div> <
            span style = {
                { fontSize: 100, fontWeight: 'bold' }
            } > { Math.round(this.state.Expected_speed * 10) / 10 } < /span> <i className='material-icons'>directions_walk</i > /min < /
            span > `` <
            /div> < /
            div > <
            /div> < /
            div >

            { /* Bottom Station Details */ } <
            div className = "card-content"
            style = {
                { position: "absolute", bottom: "10%", width: "100%", height: "20%", marginLeft: "0%", marginRight: "0%", paddingTop: 40, paddingBottom: 40, background: "rgba(74,74,74,0.3" }
            } >
            <
            div className = "row"
            style = {
                { opacity: 1.7, height: "100%", marginBottom: 0 }
            } >
            <
            div style = { styleBox.stationHeading } > Nous promettons à nos clients de ne pas dépasser une durée de { this.state.Max_time_in_queue }
            minutes dans la file d 'attente</div>

            <
            /div> < /
            div > <
            /div> < /
            div > <
            /div> < /
            div >
        );
    }
}


const styleBox = {
    stationHeading: {
        fontSize: 35,
        color: 'white',
        borderRadius: "5px 5px 0px 0px",
        fontWeight: 'bold'
    },
    stationText: {
        fontSize: 80,
        margin: 0
    }
}
export default App;