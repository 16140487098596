import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyBAV6lzdrkb-0ABVX6wyatYJERgJfBTLa8",
    authDomain: "no-waiting-um6p.firebaseapp.com",
    databaseURL: "https://no-waiting-um6p.firebaseio.com",
    projectId: "no-waiting-um6p",
    storageBucket: "no-waiting-um6p.appspot.com",
    messagingSenderId: "500583023100",
    appId: "1:500583023100:web:4ac75b0cb9677ab9b93279"
  };// export const firsbase = firebase.initializeApp(firebaseConfig);
var firebaseconfig = firebase.initializeApp(firebaseConfig);
export var database =  firebaseconfig.database();
export var firestoreObj =  firebaseconfig.firestore();